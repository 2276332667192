import { BREAKPOINTS } from "./breakpoints";
import { COLOR_PALETTE } from "./color-palette";
import { MEDIA_QUERIES } from "./media-queries";
import { TOKENS } from "./token";
import type { Theme } from "./types";
import { TYPOGRAPHY } from "./typography";

export const theme: Theme = {
	breakpoints: BREAKPOINTS,
	grid: {
		gutter: {
			s: 8,
			m: 8,
			l: 12,
		},
		gridPadding: {
			s: 16,
			m: 24,
			l: 24,
		},
		padding: {
			s: 0,
			m: 0,
			l: 0,
		},
		maxWidth: 1128,
		colSpan: {
			s: 4,
			m: 8,
			l: 12,
		},
	},
	layout: {
		header: {
			height: 72,
		},
	},
	mq: MEDIA_QUERIES,
	palette: COLOR_PALETTE,
	spaces: {
		xxxxs: 4,
		xxxs: 8,
		xxs: 16,
		xs: 24,
		s: 32,
		m: 40,
		l: 48,
		xl: 64,
		xxl: 80,
		xxxl: 120,
		xxxxl: 184,
	},
	tokens: TOKENS,
	typography: TYPOGRAPHY,
};
