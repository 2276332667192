export interface ValuationData {
	currency: string;
	valueRange: { lower: number; upper: number };
}

export enum FormKeys {
	address = "address",
	propertyType = "propertyType",
	buildingYear = "buildingYear",
	livingArea = "livingArea",
	landArea = "landArea",
	valuationIntent = "valuationIntent",
	valuationTime = "valuationTime",
	firstName = "firstName",
	lastName = "lastName",
	email = "email",
	phoneNumber = "phoneNumber",
	consent = "consent",
	newsletterConsent = "newsletterConsent",
}

export enum PropertyTypeValues {
	apartment = "AP",
	house = "HO",
}

export enum PropertyType {
	AP = "apartment",
	HO = "house",
}

export enum ValuationPropertyCondition {
	new = "new",
	veryGood = "very-good",
	used = "used",
	ruin = "ruin",
	other = "other",
}

export enum ValuationIntentValues {
	selling = "SE",
	buying = "BY",
	info = "IN",
	other = "OT",
}

export enum ValuationIntent {
	SE = "sell",
	BY = "buy",
	IN = "info",
	OT = "other",
}

export enum ValuationTimeValues {
	now = "NO",
	halfYear = "HY",
	oneYear = "YE",
	unknown = "UN",
}

export interface PersonalState {
	[FormKeys.firstName]: string;
	[FormKeys.lastName]: string;
	[FormKeys.email]: string;
	[FormKeys.phoneNumber]: string;
	[FormKeys.consent]: boolean;
	[FormKeys.newsletterConsent]: boolean;
}

export type ValuationUserData = {
	id: number;
	created: string;
	modified: string;
	uuid: string;
	propertyType: PropertyTypeValues;
	buildingYear?: number;
	livingArea: number;
	landArea?: number;
	condition?: ValuationPropertyCondition;
	bedrooms?: number;
	postCode: string;
	city: string;
	street: string;
	houseNumber: string;
	countryCode: string;
	locationLatitude: number;
	locationLongitude: number;
	valuationIntent: ValuationIntentValues;
	valuationTime: ValuationTimeValues;
	firstName: string;
	lastName: string;
	email: string;
	phoneNumber: string | null;
};

export type PriceHubbleValuationPayload = {
	dealType: string;
	property: {
		location: {
			address: {
				city: string;
				houseNumber: string;
				postCode: string;
				street: string;
			};
			coordinates: {
				latitude: number;
				longitude: number;
			};
		};
		propertyType: {
			code: PropertyType;
		};
		buildingYear: number;
		livingArea: number;
		landArea?: number;
		condition: string;
	};
	countryCode: string;
};
