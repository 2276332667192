import React from "react";

export const useTagManager = (): void => {
	React.useEffect(() => {
		if (typeof window === "undefined" || window.dataLayer) {
			return;
		}

		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push("consent", "default", {
			ad_storage: "denied",
			analytics_storage: "denied",
		});
	}, []);
};

export const useTrackPage = (asPath: string | null): void => {
	const dataLayerName = "PageDataLayer";

	React.useEffect(() => {
		if (asPath) {
			window[dataLayerName] = window[dataLayerName] || [];
			window[dataLayerName].push({
				page: asPath,
			});
		}
	}, [asPath]);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useTrackingEvent = (mainArgs?: Record<string, any>) => {
	return React.useCallback(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(args?: Record<string, any>) => {
			window.dataLayer.push({ ...mainArgs, ...args });
		},
		[mainArgs]
	);
};

export const useTrackFeature = () => {
	const trackEvent = useTrackingEvent({ event: "track_feature" });
	return (feature: string, value: string = "") => {
		trackEvent({ feature, value });
	};
};
