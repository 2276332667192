import React from "react";

import { humanize } from "@/utils/string";

import type { IconProps} from "./types";
import { IconSize } from "./types";

export const Icon: React.FC<IconProps> = ({ icon, size = IconSize.m, ...rest }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 24 24"
			fill="currentColor"
			height={size}
			width={size}
			{...rest}
		>
			<title>{humanize(icon)}</title>
			<use href={`/assets/svg-icons/${icon}.svg#main`} />
		</svg>
	);
};
