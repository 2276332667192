import React from "react";

import { Loader } from "@googlemaps/js-api-loader";

import { getLoader, setLoader } from "@/components/google-maps/loader";

const GoogleMapApiContext = React.createContext({
	loaded: false,
	libraries: [],
});
export const useGoogleMapApiContext = () => React.useContext(GoogleMapApiContext);

export const GoogleMapApi: React.FC<{ libraries: Loader["libraries"] }> = ({
	children,
	libraries,
}) => {
	const [loaded, setLoaded] = React.useState(false);
	const [error, setError] = React.useState<null | Error>(null);

	React.useEffect(() => {
		const subscribe = () => {
			// Already loaded, we can safely return
			if (loaded) {
				return;
			}

			// Already on window, we can set loading to true and safely return
			if (window.google) {
				setLoaded(true);
				return;
			}

			// We need to load the library
			// check for existing loader before creating a new one
			const loader =
				getLoader() ||
				new Loader({
					apiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_KEY,
					libraries,
				});
			loader
				.load()
				.then(() => {
					// Once loaded we can set loading to true
					setLoaded(true);
				})
				.catch(error_ => {
					setError(error_);
				});
			// Set the loader to allow reusing in other contexts
			setLoader(loader);
			// @todo This always unsubscribes
			// return () => {
			// 	console.log("unsubscribe");
			// 	loader.deleteScript();
			// 	window.google = undefined;
			// 	setLoaded(false);
			// };
		};

		// @todo Enable with solution for unsubscribe
		// return subscribe();
		subscribe();
	}, [loaded, libraries]);

	const context = React.useMemo(() => ({ loaded, libraries, error }), [loaded, libraries, error]);

	return <GoogleMapApiContext.Provider value={context}>{children}</GoogleMapApiContext.Provider>;
};
