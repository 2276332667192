/**
 * Website Color Palette
 * - This is a subset of a full "Evernest Unleashed Color Palette".
 * - Only add colors from the overall palette if they are used.
 * - If you need to add a new color, you can find it at: https://www.figma.com/file/aevtl1lGGCytHeiIvvWTlI/Website-Foundation?type=design&node-id=1702%3A1464&mode=design&t=nPvISgUmSNnbX506-1
 */
export const COLOR_PALETTE = {
	blue: {
		200: "#BBDCFC",
		400: "#6CAFF7",
		500: "#4C8EF3",
		600: "#356FE6",
	},
	freeze: {
		0: "#FFFFFF",
		25: "#FCFCFC",
		50: "#F8F8F8",
		100: "#F2F2F2",
		200: "#EBEBEB",
		300: "#DFDFDF",
		400: "#A6A8AB",
		500: "#6A6E75",
		600: "#53565C",
		700: "#252629",
		800: "#191A1C",
		900: "#0F1011",
		1000: "#000000",
	},
	green: {
		500: "#12B76A",
	},
	red: {
		500: "#F04438",
	},
};
