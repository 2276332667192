export interface PlaceData {
	house_number: string | null;
	post_code: string | null;
	country_code: string | null;
	city: string | null;
	state: string | null;
	street: string | null;
	location_latitude: number | null;
	location_longitude: number | null;
}

export const getGeocodeByAddress = async (address: string): Promise<PlaceData> => {
	try {
		const response = await fetch("/api/google-geocode/", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({ address }),
		});

		if (!response.ok) {
			throw new Error("Error fetching from geocode API");
		}

		const data = await response.json();

		const [
			{
				geometry: { location },
				address_components,
			},
		] = data.results;

		const house_number = address_components.find(entry =>
			entry.types.includes("street_number")
		);
		const post_code = address_components.find(entry => entry.types.includes("postal_code"));
		const country_code = address_components.find(entry => entry.types.includes("country"));
		const state = address_components.find(entry =>
			entry.types.includes("administrative_area_level_1")
		);
		const city = address_components.find(entry => entry.types.includes("locality"));
		const street = address_components.find(entry => entry.types.includes("route"));

		return {
			house_number: house_number?.long_name,
			post_code: post_code?.long_name,
			country_code: country_code?.short_name,
			city: city?.long_name,
			street: street?.long_name,
			state: state?.long_name,
			location_latitude: location.lat,
			location_longitude: location.lng,
		};
	} catch (error: unknown) {
		console.error(error);
	}
};
