/**
 * Breakpoints for responsive design.
 *  s: 320,
	m: 768,
	l: 1200,
	xl: 1200,
 */
export const BREAKPOINTS = {
	s: 320,
	m: 768,
	l: 1200,
	xl: 1200, // @ToDo: why is this the same? Check git history.
};
