import { theme } from "./evernest";
import { FONT_WEIGHT, TYPOGRAPHY } from "./typography";

/**
 * Buttons theming
 * Design: https://www.figma.com/file/cYVmvu20RplQZs7ns7qxHM/Website-Components?type=design&node-id=3916-1516&mode=design&t=GicRN5BgCeNjJUQF-0
 */

export const ButtonSizes = {
	XL: {
		borderRadius: theme.tokens.borderRadius.lg,
		padding: "14px 18px 13px 18px",
	},
	LG: {
		borderRadius: theme.tokens.borderRadius.md,
		padding: "10px 14px 9px 14px",
	},
	MD: {
		borderRadius: theme.tokens.borderRadius.md,
		padding: "8px 10px 7px 10px",
	},
};

const sharedButtonStyles = {
	border: "none",
	cursor: "pointer",
	display: "flex",
	placeItems: "center",
};

const sharedTextButtonStyles = {
	background: "none",
	fontWeight: FONT_WEIGHT.medium,
};

export const ButtonVariantStyles = {
	primary: {
		...sharedButtonStyles,
		background: theme.palette.freeze[1000],
		color: theme.palette.freeze[0],
		hover: {
			background: theme.palette.freeze[900],
		},
		active: {
			background: theme.palette.freeze[700],
		},
		disabled: {
			background: theme.palette.freeze[300],
		},
		loading: {
			background: theme.palette.freeze[300],
		},
	},
	secondary: {
		...sharedButtonStyles,
		background: theme.palette.freeze[0],
		boxShadow: `${theme.tokens.border.insetRegular} ${theme.palette.freeze[1000]}`,
		color: theme.palette.freeze[1000],
		hover: {
			background: theme.palette.freeze[50],
		},
		active: {
			background: theme.palette.freeze[100],
		},
		disabled: {
			background: theme.palette.freeze[0],
			boxShadow: `${theme.tokens.border.insetRegular} ${theme.palette.freeze[200]}`,
			color: theme.palette.freeze[200],
		},
		loading: {
			background: theme.palette.freeze[0],
			boxShadow: `${theme.tokens.border.insetRegular} ${theme.palette.freeze[200]}`,
			color: theme.palette.freeze[200],
		},
	},
	tertiary: {
		...sharedButtonStyles,
		background: theme.palette.freeze[0],
		boxShadow: `${theme.tokens.border.insetRegular} ${theme.palette.freeze[200]}`,
		color: theme.palette.freeze[1000],
		hover: {
			background: theme.palette.freeze[50],
		},
		active: {
			background: theme.palette.freeze[100],
		},
		disabled: {
			background: theme.palette.freeze[0],
			color: theme.palette.freeze[200],
		},
		loading: {
			background: theme.palette.freeze[0],
			color: theme.palette.freeze[200],
		},
	},
	primaryWhite: {
		...sharedButtonStyles,
		background: theme.palette.freeze[0],
		color: theme.palette.freeze[1000],
		hover: {
			background: theme.palette.freeze[50],
		},
		active: {
			background: theme.palette.freeze[100],
		},
		disabled: {
			color: theme.palette.freeze[300],
		},
		loading: {
			color: theme.palette.freeze[300],
		},
	},
	secondaryWhite: {
		...sharedButtonStyles,
		background: "none",
		boxShadow: `${theme.tokens.border.insetRegular} ${theme.palette.freeze[0]}`,
		color: theme.palette.freeze[0],
		hover: {
			boxShadow: `${theme.tokens.border.insetRegular} ${theme.palette.freeze[50]}`,
			color: theme.palette.freeze[50],
		},
		active: {
			boxShadow: `${theme.tokens.border.insetRegular} ${theme.palette.freeze[100]}`,
			color: theme.palette.freeze[100],
		},
		disabled: {
			boxShadow: `${theme.tokens.border.insetRegular} ${theme.palette.freeze[0]}`,
			color: theme.palette.freeze[0],
		},
		loading: {
			boxShadow: `${theme.tokens.border.insetRegular} ${theme.palette.freeze[0]}`,
			color: theme.palette.freeze[0],
		},
	},
};

export const TextButtonSizes = {
	XL: {
		...TYPOGRAPHY.buttonLabels.LG,
	},
	LG: {
		...TYPOGRAPHY.buttonLabels.MD,
	},
};

export const TextButtonVariantStyles = {
	primaryText: {
		...sharedButtonStyles,
		...sharedTextButtonStyles,
		color: theme.palette.blue[500],
		hover: {
			color: theme.palette.blue[600],
		},
		active: {
			color: theme.palette.blue[400],
		},
		disabled: {
			color: theme.palette.blue[200],
		},
		loading: {
			color: theme.palette.freeze[0],
		},
	},
	secondaryText: {
		...sharedButtonStyles,
		...sharedTextButtonStyles,
		color: theme.palette.freeze[1000],
		hover: {
			color: theme.palette.freeze[700],
		},
		active: {
			color: theme.palette.freeze[600],
		},
		disabled: {
			color: theme.palette.freeze[300],
		},
		loading: {
			color: theme.palette.freeze[0],
		},
	},
	tertiaryText: {
		...sharedButtonStyles,
		...sharedTextButtonStyles,
		color: theme.palette.freeze[500],
		hover: {
			color: theme.palette.freeze[800],
		},
		active: {
			color: theme.palette.freeze[700],
		},
		disabled: {
			color: theme.palette.freeze[300],
		},
		loading: {
			color: theme.palette.freeze[0],
		},
	},
};
