import { FormType } from "@/components/forms/";

// These remaining items are unrelated to contact form submission and are used in other areas.
export const TRACKING_PAYLOADS = {
	[FormType.VALUATION]: {
		"Event Category": "conversion",
		"Event Action": "valuation_submission",
		"Event Label": "info",
		event: "valuationForm",
	},
	[FormType.VALUATION_FUNNEL]: [
		{
			"Event Category": "Valuation Form",
			"Event Action": "valuation_0_start",
			"Event Label": "",
			event: "valuationFunnel",
		},
		{
			"Event Category": "Valuation Form",
			"Event Action": "valuation_1_propertytype",
			"Event Label": "",
			event: "valuationFunnel",
		},
		{
			"Event Category": "Valuation Form",
			"Event Action": "valuation_2_propertydata",
			"Event Label": "",
			event: "valuationFunnel",
		},
		{
			"Event Category": "Valuation Form",
			"Event Action": "valuation_3_reason",
			"Event Label": "",
			event: "valuationFunnel",
		},
		{
			"Event Category": "Valuation Form",
			"Event Action": "valuation_1.5_propertycondition",
			"Event Label": "",
			event: "valuationFunnel",
		},
	],
};
