import React, { createContext, useCallback, useContext, useEffect, useState } from "react";

import { endpoints } from "@/endpoints";

import type {
	JobsContextType,
	JobsFilters,
	JobsFilterOptions,
	JobLocation,
	JobsProviderProps,
	PartialJob,
} from "./types";

const initialJobFilterOptions: JobsFilterOptions = {
	/**
	 * The countries value is hard coded as it's not possible to discern the
	 * values from the jobs data. However, we can filter loosely for these values.
	 */
	countries: ["Germany", "Spain"],
	departments: [],
	locations: [],
};

const JobsContext = createContext<JobsContextType>({
	error: false,
	filterOptions: initialJobFilterOptions,
	loading: true,
	filterJobs: () => [],
	searchJobsByQuery: () => [],
});

// Departments that have been explicitly excluded in the Contentful settings for this job list
const filterOutDepartments = (jobs: PartialJob[], excludedDepartments: string[]) =>
	jobs.filter(
		job => !job.departments.some(department => excludedDepartments.includes(department.name))
	);

export const useJobsContext = (): JobsContextType => {
	const context = useContext(JobsContext);

	if (!context) {
		throw new Error("useJobs must be used within a JobsProvider");
	}

	return context;
};

export const JobsProvider = ({ children }: JobsProviderProps) => {
	const [allJobs, setAllJobs] = useState<PartialJob[]>([]);
	const [filterOptions, setFilterOptions] = useState<JobsFilterOptions>(initialJobFilterOptions);
	const [error, setError] = useState(false);
	const [loading, setLoading] = useState(true);

	const fetchDataFromGreenhouse = useCallback(async () => {
		try {
			setError(false);
			const response = await fetch(endpoints.greenhouse.jobBoard.get());
			const data = await response.json();
			const jobs = data.jobs;

			// Extract unique department names for e.g. select menus
			const allDepartmentNames = jobs
				.flatMap((job: PartialJob) => job.departments.map(dept => dept.name))
				.sort();
			const uniqueDepartmentNames = Array.from(new Set(allDepartmentNames)) as string[];

			// Extract unique locations from jobs
			// Use a Map to ensure unique locations by locationShort
			const locationMap: Map<string, JobLocation> = new Map();
			jobs.forEach((job: PartialJob) => {
				const locationFull = job.location.name;
				const locationShort = locationFull.split(",")[0];
				if (!locationMap.has(locationShort)) {
					locationMap.set(locationShort, {
						locationShort,
						locationFull,
					});
				}
			});

			// Convert the Map values to an array and sort by locationShort
			const sortedLocations = Array.from(locationMap.values()).sort((a, b) =>
				a.locationShort.localeCompare(b.locationShort)
			);

			setFilterOptions(state => ({
				...state,
				departments: uniqueDepartmentNames,
				locations: sortedLocations,
			}));
			setAllJobs(jobs);
		} catch (error) {
			setError(true);
			console.error("Failed to fetch data from the Greenhouse API:", error);
		} finally {
			setLoading(false);
		}
	}, []);

	const filterJobs = useCallback(
		(filters: JobsFilters | undefined) => {
			// No filters set, return all jobs
			if (!filters) {
				return filterOutDepartments(allJobs, filters.excludedDepartments);
			}

			const { department, country, location, excludedDepartments } = filters;

			// Filter jobs according to one or more set filters
			return filterOutDepartments(allJobs, excludedDepartments).filter(job => {
				let matchesDepartment = true;
				let matchesCountry = true;
				let matchesLocation = true;

				// Filter by department if set
				if (Boolean(department)) {
					matchesDepartment = job.departments.some(item => item.name === department);
				}

				// Filter by country if set
				if (Boolean(country)) {
					matchesCountry =
						job.location.name.includes(country) ||
						job.offices.some(
							item =>
								(item.location?.includes(country) ?? false) ||
								item.name.includes(country)
						);
				}

				// Filter by location if set
				if (Boolean(location)) {
					matchesLocation = job.location.name.includes(location);
				}

				return matchesDepartment && matchesCountry && matchesLocation;
			});
		},
		[allJobs]
	);

	// We do wide matching for the users query here against, job title, locations, and departments
	const searchJobsByQuery = useCallback(
		(query: string) =>
			allJobs.filter(job => {
				let matchesTitle = false;
				let matchesDepartment = false;
				let matchesLocation = false;
				let matchesKeywords = false;

				const lowercasedQuery = query.toLowerCase();

				// Match query against job title
				matchesTitle = job.title.toLowerCase().includes(lowercasedQuery);

				// Match query against department
				matchesDepartment = job.departments.some(item =>
					item.name.toLowerCase().includes(lowercasedQuery)
				);

				// Match query against location
				matchesLocation =
					job.location.name.toLowerCase().includes(lowercasedQuery) ||
					job.offices.some(
						item =>
							item.name.toLowerCase().includes(lowercasedQuery) ||
							(item.location?.toLowerCase()?.includes(lowercasedQuery) ?? false)
					);

				// Match query against Custom Field "keywords"
				matchesKeywords =
					job?.metadata
						.find(({ name }) => name === "Keywords")
						.value?.toLowerCase()
						?.includes(lowercasedQuery) || false;

				return matchesTitle || matchesDepartment || matchesLocation || matchesKeywords;
			}),
		[allJobs]
	);

	useEffect(() => {
		fetchDataFromGreenhouse();
	}, [fetchDataFromGreenhouse]);

	const value = React.useMemo(
		() => ({
			error,
			filterJobs,
			filterOptions,
			loading,
			searchJobsByQuery,
		}),
		[error, filterJobs, filterOptions, loading, searchJobsByQuery]
	);

	return <JobsContext.Provider value={value}>{children}</JobsContext.Provider>;
};
