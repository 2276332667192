import createTheme from "@material-ui/core/styles/createTheme";

import { theme } from "./evernest";
import { FONT_FACES } from "./typography";

const typography = {
	fontFamily: FONT_FACES.sans,
	fontWeightRegular: 300,
};

const commonTheme = {
	palette: {
		secondary: {
			main: theme.palette.freeze[1000],
		},
		warning: {
			main: theme.palette.red[500],
		},
		success: {
			main: theme.palette.green[500],
		},
		error: {
			main: theme.palette.red[500],
		},
	},
	typography,
	breakpoints: {
		values: {
			xs: 0,
			sm: theme.breakpoints.s,
			md: theme.breakpoints.m,
			lg: theme.breakpoints.l,
			xl: theme.breakpoints.xl,
		},
	},
};

export const MUITheme = createTheme(
	{
		typography,
		palette: {
			primary: {
				main: theme.palette.freeze[1000],
			},
			secondary: {
				main: theme.palette.freeze[1000],
			},
		},
	},
	commonTheme
);
