import type { CSSProperties } from "react";
import React, { forwardRef, useMemo } from "react";

import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { MEDIA_QUERIES, theme } from "@/theme";

import type { StyledTypographyProps, TypographyElement } from "../../theme/types";
import { TypographyVariant } from "../../theme/types";

const variants: Record<TypographyVariant, CSSProperties | TypographyVariant> = {
	// SANS
	[TypographyVariant.headlineSans2XL]: theme.typography.headlines.sans["2XL"],
	[TypographyVariant.headlineSansXL]: theme.typography.headlines.sans["XL"],
	[TypographyVariant.headlineSansLG]: theme.typography.headlines.sans["LG"],
	[TypographyVariant.headlineSansMD]: theme.typography.headlines.sans["MD"],
	[TypographyVariant.headlineSansSM]: theme.typography.headlines.sans["SM"],
	[TypographyVariant.headlineSansXS]: theme.typography.headlines.sans["XS"],
	[TypographyVariant.headlineSansXXS]: theme.typography.headlines.sans["XXS"],

	// SERIF
	[TypographyVariant.headlineSerif2XL]: theme.typography.headlines.serif["2XL"],
	[TypographyVariant.headlineSerifXL]: theme.typography.headlines.serif["XL"],
	[TypographyVariant.headlineSerifLG]: theme.typography.headlines.serif["LG"],
	[TypographyVariant.headlineSerifMD]: theme.typography.headlines.serif["MD"],
	[TypographyVariant.headlineSerifSM]: theme.typography.headlines.serif["SM"],
	[TypographyVariant.headlineSerifXS]: theme.typography.headlines.serif["XS"],
	[TypographyVariant.headlineSerifXXS]: theme.typography.headlines.serif["XXS"],

	// BODY, TITLE, CAPTION
	[TypographyVariant.bodyXL]: theme.typography.body["XL"],
	[TypographyVariant.bodyLG]: theme.typography.body["LG"],
	[TypographyVariant.bodyMD]: theme.typography.body["MD"],
	[TypographyVariant.bodySM]: theme.typography.body["SM"],
	[TypographyVariant.bodyXS]: theme.typography.body["XS"],

	// LABEL
	[TypographyVariant.labelLG]: theme.typography.buttonLabels["LG"],
	[TypographyVariant.labelMD]: theme.typography.buttonLabels["MD"],
	[TypographyVariant.labelSM]: theme.typography.buttonLabels["SM"],
};

const DefaultTypography = styled.p<StyledTypographyProps>`
	${({
		bottom,
		centered,
		centeredOnMobile,
		light,
		top,
		tight,
		variant = variants[TypographyVariant.bodyLG],
		weight,
	}) => css`
		${{ ...(variant as CSSProperties) }}
		color: ${light && theme.palette.freeze[500]};
		margin-top: ${(tight || bottom) && 0};
		margin-bottom: ${(tight || top) && 0};
		font-weight: ${weight};
		text-align: ${centeredOnMobile && "center"};
		@media ${MEDIA_QUERIES.l} {
			text-align: ${centered && "center"};
		}
	`};
`;

/**
 * Typography foundation
 * Typography can vary in variant (including font, size, weight, and line height)
 * @default variant: BodyLG & font-weight: light
 * Design: https://www.figma.com/file/aevtl1lGGCytHeiIvvWTlI/Website-Foundation?type=design&node-id=0-1&mode=design&t=HSLyfYBPwPfA5GC5-0
 */
export const Typography = forwardRef<TypographyElement, StyledTypographyProps>(
	({ variant, component = "p", ...props }, ref) => {
		const Component = useMemo(
			() => DefaultTypography.withComponent(component),
			[DefaultTypography, component]
		);

		const TypographyComponent = useMemo(
			() => (
				<Component
					variant={variant && (variants[variant] as TypographyVariant)}
					{...props}
					ref={ref}
				/>
			),
			[props, variant]
		);

		return TypographyComponent;
	}
);
