import React from "react";

import { useRouter } from "next/router";

import { TRACKING_PAYLOADS } from "@/constants/tracking";
import { endpoints } from "@/endpoints";
import { useNewsletterSignup } from "@/hooks/newsletter";
import { useTrackingEvent } from "@/hooks/tag-manager";
import { getUTMParameters } from "@/services/google-analytics/utm";
import { getGeocodeByAddress } from "@/services/google-maps";
import type {
	PropertyTypeValues,
	ValuationData,
	ValuationIntentValues,
	ValuationPropertyCondition,
	ValuationTimeValues,
} from "@/types/valuation";
import { ValuationIntent as Intent } from "@/types/valuation";

interface Initiator {
	pathname: string;
	asPath: string;
}
interface PropertyDetails {
	buildingYear?: string;
	condition?: ValuationPropertyCondition | null;
	livingArea?: string;
	landArea?: string;
	bedrooms?: string;
}
interface ValuationIntent {
	valuationIntent: ValuationIntentValues | null;
	valuationTime: ValuationTimeValues | null;
}
interface PersonalInfo {
	firstName: string;
	lastName: string;
	email: string;
	phoneNumber: string;
	consent: boolean;
	newsletterConsent: boolean;
}
interface ValuationContextInterface {
	state: {
		initiator: Initiator;
		address: {
			userInput: string;
			location: {
				street: string;
				houseNumber: string;
				city: string;
				postCode: string;
				countryCode: string;
				latitude: number;
				longitude: number;
			};
		};
		propertyType: PropertyTypeValues | null;
		propertyDetails: PropertyDetails;
		valuationIntent: ValuationIntent;
		personalInfo: PersonalInfo;
		valuation: ValuationData;
	};

	updateInitiator(value: Initiator): void;
	updateAddress(value: string): Promise<void>;
	updatePropertyType(value: PropertyTypeValues): void;
	updatePropertyCondition(value: PropertyDetails): void;
	updatePropertyDetails(value: PropertyDetails): void;
	updateValuationIntent(value: ValuationIntent): Promise<void>;
	updatePersonalInfo(value: PersonalInfo): void;
}

const ValuationContextPlaceholder = {
	state: {
		initiator: {
			pathname: "",
			asPath: "",
		},
		address: {
			userInput: "",
			location: {
				street: "",
				houseNumber: "",
				city: "",
				postCode: "",
				countryCode: "",
				latitude: Number.NaN,
				longitude: Number.NaN,
			},
		},
		propertyType: null,
		propertyDetails: {
			buildingYear: "",
			condition: null,
			livingArea: "",
			landArea: "",
			bedrooms: "",
		},
		valuationIntent: {
			valuationIntent: null,
			valuationTime: null,
		},
		personalInfo: {
			firstName: "",
			lastName: "",
			email: "",
			phoneNumber: "",
			consent: false,
			newsletterConsent: false,
		},
		valuation: {
			currency: "",
			valueRange: {
				lower: Number.NaN,
				upper: Number.NaN,
			},
		},
	},
	updateInitiator: () => {
		// Intentionally left blank
	},
	updateAddress: async () => {
		// Intentionally left blank
	},
	updatePropertyType: () => {
		// Intentionally left blank
	},
	updatePropertyCondition: () => {
		// Intentionally left blank
	},
	updatePropertyDetails: () => {
		// Intentionally left blank
	},
	updateValuationIntent: async () => {
		// Intentionally left blank
	},
	updatePersonalInfo: () => {
		// Intentionally left blank
	},
};

export const ValuationContext = React.createContext<ValuationContextInterface>(
	ValuationContextPlaceholder
);

export const ValuationProvider: React.FC = ({ children }) => {
	const trackValuationEvent = useTrackingEvent(TRACKING_PAYLOADS.VALUATION);
	const trackValuationFunnelEvent = useTrackingEvent();
	const { subscribe } = useNewsletterSignup();
	const { locale, pathname } = useRouter();

	const [previousPathname, setPreviousPathname] = React.useState<string>("");
	const [state, setState] = React.useState<ValuationContextInterface["state"]>(
		ValuationContextPlaceholder.state
	);

	React.useEffect(() => {
		if (previousPathname.startsWith("/valuation/") && !pathname.startsWith("/valuation/")) {
			setState(() => ValuationContextPlaceholder.state);
		}

		setPreviousPathname(() => pathname);
	}, [pathname]);

	const updateInitiator = React.useCallback((initiator: Initiator) => {
		setState(state => ({
			...state,
			initiator,
		}));
	}, []);

	const updateAddress = React.useCallback(async (userInput: string) => {
		try {
			const placeData = await getGeocodeByAddress(userInput);
			trackValuationFunnelEvent(TRACKING_PAYLOADS.VALUATION_FUNNEL[0]);
			setState(state => ({
				...state,
				address: {
					userInput,
					location: {
						city: placeData.city,
						postCode: placeData.post_code,
						street: placeData.street,
						houseNumber: placeData.house_number,
						countryCode: placeData.country_code,
						latitude: placeData.location_latitude,
						longitude: placeData.location_longitude,
					},
				},
			}));
		} catch (error: unknown) {
			console.error(error);
		}
	}, []);

	const updatePropertyType = React.useCallback((propertyType: PropertyTypeValues) => {
		trackValuationFunnelEvent(TRACKING_PAYLOADS.VALUATION_FUNNEL[1]);
		setState(state => ({
			...state,
			propertyType,
		}));
	}, []);

	const updatePropertyCondition = React.useCallback((propertyDetails: PropertyDetails) => {
		trackValuationFunnelEvent(TRACKING_PAYLOADS.VALUATION_FUNNEL[4]);
		setState(state => ({
			...state,
			propertyDetails: {
				...state.propertyDetails,
				...propertyDetails,
			},
		}));
	}, []);

	const updatePropertyDetails = React.useCallback((propertyDetails: PropertyDetails) => {
		trackValuationFunnelEvent(TRACKING_PAYLOADS.VALUATION_FUNNEL[2]);
		setState(state => ({
			...state,
			propertyDetails: {
				...state.propertyDetails,
				...propertyDetails,
			},
		}));
	}, []);

	const updateValuationIntent = React.useCallback(async (valuationIntent: ValuationIntent) => {
		setState(state => ({
			...state,
			valuationIntent,
		}));

		trackValuationFunnelEvent(TRACKING_PAYLOADS.VALUATION_FUNNEL[3]);
	}, []);

	const updatePersonalInfo = React.useCallback((personalInfo: PersonalInfo) => {
		setState(state => {
			const payload: ValuationRequestPayload = {
				language: locale.toUpperCase(),
				lead_source: getUTMParameters(),
				property_type: state.propertyType,
				building_year: state.propertyDetails.buildingYear
					? Number(state.propertyDetails.buildingYear)
					: undefined,
				living_area: Number(state.propertyDetails.livingArea),
				land_area:
					Number(state.propertyDetails.landArea) > 0
						? Number(state.propertyDetails.landArea)
						: undefined,
				bedrooms: state.propertyDetails.bedrooms
					? Number(state.propertyDetails.bedrooms)
					: undefined,
				condition: state.propertyDetails.condition ?? undefined,
				post_code: state.address.location.postCode,
				city: state.address.location.city,
				street: state.address.location.street,
				house_number: state.address.location.houseNumber,
				country_code: state.address.location.countryCode,
				location_latitude: state.address.location.latitude,
				location_longitude: state.address.location.longitude,
				valuation_intent: state.valuationIntent.valuationIntent,
				valuation_time: state.valuationIntent.valuationTime || undefined,
				first_name: personalInfo.firstName,
				last_name: personalInfo.lastName,
				email: personalInfo.email,
				phone_number: personalInfo.phoneNumber || undefined,
			};

			// @todo: add test which checks behaviour with bad response
			void postValuationRequest(payload);

			if (personalInfo.newsletterConsent) {
				void subscribe(personalInfo.email);
			}

			trackValuationEvent({
				"Event Label": Intent[state.valuationIntent.valuationIntent],
				// https://mece.atlassian.net/browse/EDP-3807
				formData: {
					email: personalInfo.email ?? null,
					phone: personalInfo.phoneNumber ?? null,
				},
			});

			return {
				...state,
				personalInfo,
			};
		});
	}, []);

	const valuationContext = React.useMemo(
		() => ({
			state,

			updateInitiator,
			updateAddress,
			updatePropertyType,
			updatePropertyCondition,
			updatePropertyDetails,
			updateValuationIntent,
			updatePersonalInfo,
		}),
		[state]
	);

	return (
		<ValuationContext.Provider value={valuationContext}>{children}</ValuationContext.Provider>
	);
};

interface ValuationRequestPayload {
	language: string;
	lead_source: Record<string, string>;
	property_type: string;
	building_year?: number;
	living_area: number;
	land_area?: number;
	bedrooms?: number;
	condition?: ValuationPropertyCondition;
	post_code: string;
	city: string;
	street: string;
	house_number: string;
	country_code: string;
	location_latitude: number;
	location_longitude: number;
	valuation_intent: string;
	valuation_time?: string;
	first_name: string;
	last_name: string;
	email: string;
	phone_number?: string;
}
async function postValuationRequest(payload: ValuationRequestPayload): Promise<void> {
	await fetch(endpoints.lead.valuationRequest.post(), {
		method: "POST",
		headers: { "Content-Type": "application/json" },
		body: JSON.stringify(payload),
	});
}

export const useValuationContext = () => React.useContext(ValuationContext);
