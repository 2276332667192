export const TextFieldSizes = {
	XL: {
		height: "48px",
		padding: "12px 12px 11px 12px",
	},
	LG: {
		height: "40px",
		padding: "8px 10px 7px 10px",
	},
};
