import React from "react";

import Head from "next/head";

import pkg from "../../../package.json";

const CLUSTER_ENV = process.env.NEXT_PUBLIC_CLUSTER_ENV || "prod";

export const Favicons = () => {
	return (
		<Head>
			<link rel="icon" href={`/assets/favicons/${CLUSTER_ENV}/favicon.ico`} />
			<link
				rel="icon"
				href={`/assets/favicons/${CLUSTER_ENV}/favicon.svg?v=${pkg.version}`}
				type="image/svg+xml"
			/>
			<link
				rel="apple-touch-icon"
				href={`/assets/favicons/${CLUSTER_ENV}/icon-192.png?v=${pkg.version}`}
			/>
			<link rel="manifest" href={`/assets/favicons/${CLUSTER_ENV}/manifest.json`} />
		</Head>
	);
};
