/***
 * Design: https://www.figma.com/file/aevtl1lGGCytHeiIvvWTlI/Website-Foundation?type=design&node-id=2902-3534&mode=design&t=sP8x6aARM9WaC20b-0
 * Border Radius: https://www.figma.com/file/aevtl1lGGCytHeiIvvWTlI/Website-FoundationKit?type=design&node-id=2902-3536&mode=design&t=skJVnWklLizYDzCY-0
 * Elevation: https://www.figma.com/file/aevtl1lGGCytHeiIvvWTlI/Website-FoundationKit?type=design&node-id=2902-3656&mode=design&t=skJVnWklLizYDzCY-0
 */

import type { Tokens } from "./types";

export const TOKENS: Tokens = {
	animation: {
		duration: {
			SM: "0.3s",
		},
		ease: {
			smooth: {
				normal: "cubic-bezier(0.4, 0, 0.2, 1)",
				accelerated: "cubic-bezier(0.2, 0, 0, 1)",
			},
		},
		framer: {
			acceleratedSM: {
				duration: 0.3,
				ease: [0.2, 0, 0, 1],
			},
		},
	},
	borderRadius: {
		xl: "10px",
		lg: "8px",
		md: "6px",
		sm: "4px",
		"2xl": "16px",
		"3xl": "24px",
		rounded: "50%",
	},
	border: {
		insetRegular: "inset 0 0 0 1.25px",
		insetIncreased: "inset 0 0 0 2px",
	},
	boxShadow: {
		MD: "0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.1)",
	},
	elevation: {
		bottom: {
			XS: "0px 1px 2px rgba(0, 0, 0, 0.05)",
			SM: "0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.1)",
			MD: "0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.1)",
			LG: "0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05)",
		},
		left: {
			LG: "-4px 0px 6px -2px rgba(0, 0, 0, 0.05), -10px 0px 15px -3px rgba(0, 0, 0, 0.1)",
		},
	},
};
