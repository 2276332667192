import React from "react";

import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { Icon } from "@/design-system/atoms/icons";
import { FONT_FACES } from "@/theme";
import type { PropsWithTheme } from "@/theme";

export enum ButtonSize {
	small = "small",
	medium = "medium",
}

export interface ButtonProps {
	size?: ButtonSize;
	shadow?: boolean;
	fullWidth?: boolean;
	square?: boolean;
	color?: "primary" | "secondary" | "tertiary" | "";
}

const SMALL_BUTTON_WIDTH = "45px";
const MEDIUM_BUTTON_WIDTH = "56px";

export const Button = styled.button<PropsWithTheme<HTMLButtonElement> & ButtonProps>`
	display: inline-flex;
	align-items: center;
	align-self: center;
	justify-content: center;
	margin: 0;
	border: 0;
	border-radius: 10px;
	font-family: ${FONT_FACES.sans};
	${({
		theme: { palette },
		fullWidth,
		shadow,
		square,
		color = "primary",
		size = ButtonSize.medium,
	}) => css`
		box-shadow: ${shadow ? "0 16px 18px 0 rgba(0,0,0,0.13)" : "none"};
		height: ${size === ButtonSize.medium ? MEDIUM_BUTTON_WIDTH : SMALL_BUTTON_WIDTH};

		background: ${
			// @ToDo: with button refactor, revist this. New palette merged colors to same freeze[1000]
			color === "secondary"
				? palette.freeze[0]
				: color === "tertiary"
				? palette.freeze[1000]
				: palette.freeze[1000]
		};
		color: ${color === "secondary" ? palette.freeze[1000] : palette.freeze[0]};
		padding: 0 ${square ? 0 : "var(--spacing-xs)"};
		width: ${square
			? size === ButtonSize.medium
				? MEDIUM_BUTTON_WIDTH
				: SMALL_BUTTON_WIDTH
			: fullWidth
			? "100%"
			: "auto"};
		cursor: pointer;
		&[disabled] {
			cursor: not-allowed;
			opacity: 0.6;
		}
	`};
`;

export const StyledButtonIcon = styled(Button)`
	align-items: center;
`;

export const StyledButtonIconLink = styled.a`
	align-items: center;
	text-decoration: none;
`;

export const ButtonLink = Button.withComponent(StyledButtonIconLink);

export const StyledButtonLinkFullWidth = styled(ButtonLink)`
	flex: 1;
`;

const ArrowRightIcon = props => <Icon {...props} icon="arrowRight" />;

export const StyledArrowRightIcon = styled(ArrowRightIcon)`
	margin-left: var(--spacing-xxxs);
`;

export const StyledLinkWrapper = styled.div`
	display: flex;
	justify-content: center;
`;

/* @todo: define button patterns in design-system */
export const ButtonRaw = styled.button`
	padding: 0;
	border: none;
	background: none;
	cursor: pointer;
`;

export const AbsoluteIconButton = styled(ButtonRaw)<PropsWithTheme<HTMLButtonElement>>`
	display: flex;
	position: absolute;
	z-index: 1;
	top: var(--spacing-xs);
	right: var(--spacing-xs);
	align-content: center;
	align-items: center;
	justify-content: center;
	width: 40px;
	height: 40px;
	color: inherit;
`;

export const DarkCloseIconButton = styled(AbsoluteIconButton)<PropsWithTheme<HTMLButtonElement>>`
	${({ theme: { palette } }) => css`
		color: ${palette.freeze[500]};
	`};
`;
