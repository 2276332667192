import { Loader } from "@googlemaps/js-api-loader";

let loader_;
let loadPromise_;

export const getLoader = () => loader_;
export const setLoader = loader => {
	loader_ = loader;
};

export const googleMapLoader = () => {
	// Avoid api to be loaded multiple times
	if (loadPromise_) {
		return loadPromise_;
	}

	const libraries: Loader["libraries"] = ["places"];

	if (typeof window === "undefined") {
		throw new TypeError("google map cannot be loaded outside browser env");
	}

	// Use single instance of Loader to avoid multiple api loads
	if (!loader_) {
		loader_ = new Loader({
			apiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_KEY,
			libraries,
		});
	}

	loadPromise_ = loader_.load().then(() => {
		return window.google.maps;
	});

	return loadPromise_;
};
