const extractUTMParameterFromCookies = (cookies: string[], parameter: string): string | undefined =>
	cookies
		.find(c => c.includes(parameter))
		?.split(".")
		?.pop();

const nullifyEmptyCookie = (value: string | null): string | null =>
	!value || value === "" ? null : value;

export const getUTMParameters = (): Record<string, string | null> => {
	const cookies = document.cookie.split(";");
	const searchParams = new Map(new URLSearchParams(window.location.search).entries()); // Use Map to preserve null values
	if (!searchParams.has("gclid") || searchParams.get("gclid") === "") {
		searchParams.set(
			"gclid",
			nullifyEmptyCookie(extractUTMParameterFromCookies(cookies, "_gcl_aw"))
		);
	}

	if (!searchParams.has("fbclid") || searchParams.get("fbclid") === "") {
		searchParams.set(
			"fbclid",
			nullifyEmptyCookie(extractUTMParameterFromCookies(cookies, "_fbc"))
		);
	}

	return {
		...Object.fromEntries(searchParams.entries()),
		referrer: document.referrer,
		contextUserAgent: navigator.userAgent,
		userAgent: navigator.userAgent,
		url: window.location.origin + window.location.pathname,
	};
};
