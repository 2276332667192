import { COLOR_PALETTE } from "./color-palette";
import { MEDIA_QUERIES } from "./media-queries";

/**
 * Font faces of the website
 * there are currently two font faces available: sans-serif and serif
 * Font variations are defined in src/pages/styles.css
 */
export const FONT_FACES = {
	sans: "object_sans, sans-serif",
	serif: "made_mirage, serif",
};

/**
 * Font weights available for the website
 * There are three weights provided (light=300, regular=400, medium=500)
 * "bolder" and "lighter" css values should not be used anymore
 */
export const FONT_WEIGHT = {
	light: 300,
	regular: 400,
	medium: 500,
};

/**
 * Letter spacing available for the website
 */
export const LETTER_SPACING = {
	none: "0em",
	xsNegative: "-0.01em",
	smNegative: "-0.015em",
	mdNegative: "-0.02em",
	xsPositive: "0.01em",
	smPositive: "0.015em",
	mdPositive: "0.02em",
};

const typographySharedBody = {
	fontFamily: FONT_FACES.sans,
	letterSpacing: LETTER_SPACING.xsNegative,
	fontWeight: FONT_WEIGHT.regular,
	"> a": {
		color: COLOR_PALETTE.blue[500],
		textDecoration: "none",
		"&:hover": {
			color: COLOR_PALETTE.blue[600],
			textDecoration: "underline",
		},
	},
};

const typographySharedHeadlinesSans = {
	fontFamily: FONT_FACES.sans,
	fontWeight: FONT_WEIGHT.medium,
	letterSpacing: LETTER_SPACING.none,
};

const typographySharedHeadlinesSerif = {
	fontFamily: FONT_FACES.serif,
	fontWeight: FONT_WEIGHT.medium,
	letterSpacing: LETTER_SPACING.none,
};

/**
 * Typography styles for the website
 * The structure follows the systematic approach of the design system
 * and so can easily be applied to the components
 */
export const TYPOGRAPHY = {
	headlines: {
		sans: {
			"2XL": {
				...typographySharedHeadlinesSans,
				fontSize: "33px",
				lineHeight: "40px",
				[`@media ${MEDIA_QUERIES.l}`]: {
					fontSize: "52px",
					lineHeight: "60px",
				},
			},
			XL: {
				...typographySharedHeadlinesSans,
				fontSize: "29px",
				lineHeight: "36px",
				[`@media ${MEDIA_QUERIES.l}`]: {
					fontSize: "42px",
					lineHeight: "52px",
				},
			},
			LG: {
				...typographySharedHeadlinesSans,
				fontSize: "27.5px",
				lineHeight: "32px",
				[`@media ${MEDIA_QUERIES.l}`]: {
					fontSize: "34px",
					lineHeight: "44px",
				},
			},
			MD: {
				...typographySharedHeadlinesSans,
				fontSize: "24.5px",
				lineHeight: "28px",
				[`@media ${MEDIA_QUERIES.l}`]: {
					fontSize: "29px",
					lineHeight: "38px",
				},
			},
			SM: {
				...typographySharedHeadlinesSans,
				fontSize: "23px",
				lineHeight: "26px",
				[`@media ${MEDIA_QUERIES.l}`]: {
					fontSize: "26px",
					lineHeight: "36px",
				},
			},
			XS: {
				...typographySharedHeadlinesSans,
				fontSize: "21.5px",
				lineHeight: "24px",
				[`@media ${MEDIA_QUERIES.l}`]: {
					fontSize: "24px",
					lineHeight: "32px",
				},
			},
			XXS: {
				...typographySharedHeadlinesSans,
				fontSize: "20px",
				lineHeight: "24px",
				[`@media ${MEDIA_QUERIES.l}`]: {
					fontSize: "20px",
					lineHeight: "28px",
				},
			},
		},
		serif: {
			"2XL": {
				...typographySharedHeadlinesSerif,
				fontSize: "33px",
				lineHeight: "40px",
				fontWeight: FONT_WEIGHT.regular,
				[`@media ${MEDIA_QUERIES.l}`]: {
					fontSize: "52px",
					lineHeight: "60px",
				},
			},
			XL: {
				...typographySharedHeadlinesSerif,
				fontSize: "29px",
				lineHeight: "36px",
				fontWeight: FONT_WEIGHT.regular,
				[`@media ${MEDIA_QUERIES.l}`]: {
					fontSize: "42px",
					lineHeight: "52px",
				},
			},
			LG: {
				...typographySharedHeadlinesSerif,
				fontSize: "27.5px",
				lineHeight: "32px",
				fontWeight: FONT_WEIGHT.regular,
				[`@media ${MEDIA_QUERIES.l}`]: {
					fontSize: "34px",
					lineHeight: "44px",
				},
			},
			MD: {
				...typographySharedHeadlinesSerif,
				fontSize: "24.5px",
				lineHeight: "28px",
				fontWeight: FONT_WEIGHT.regular,
				[`@media ${MEDIA_QUERIES.l}`]: {
					fontSize: "29px",
					lineHeight: "38px",
				},
			},
			SM: {
				...typographySharedHeadlinesSerif,
				fontSize: "23px",
				lineHeight: "26px",
				fontWeight: FONT_WEIGHT.regular,
				[`@media ${MEDIA_QUERIES.l}`]: {
					fontSize: "26px",
					lineHeight: "36px",
				},
			},
			XS: {
				...typographySharedHeadlinesSerif,
				fontSize: "21.5px",
				lineHeight: "24px",
				fontWeight: FONT_WEIGHT.regular,
				[`@media ${MEDIA_QUERIES.l}`]: {
					fontSize: "24px",
					lineHeight: "32px",
				},
			},
			XXS: {
				...typographySharedHeadlinesSerif,
				fontSize: "20px",
				lineHeight: "24px",
				fontWeight: FONT_WEIGHT.regular,
				[`@media ${MEDIA_QUERIES.l}`]: {
					fontSize: "20px",
					lineHeight: "28px",
				},
			},
		},
	},
	body: {
		XL: {
			...typographySharedBody,
			fontSize: "18px",
			lineHeight: "28px",
		},
		LG: {
			...typographySharedBody,
			fontSize: "16px",
			lineHeight: "26px",
			fontWeight: FONT_WEIGHT.light,
		},
		MD: {
			...typographySharedBody,
			fontSize: "14px",
			lineHeight: "20px",
		},
		SM: {
			...typographySharedBody,
			fontSize: "12px",
			lineHeight: "18px",
		},
		XS: {
			...typographySharedBody,
			fontSize: "10px",
			lineHeight: "16px",
			letterSpacing: LETTER_SPACING.none,
		},
	},
	buttonLabels: {
		LG: {
			fontSize: "16px",
			lineHeight: "25px",
			letterSpacing: LETTER_SPACING.none,
		},
		MD: {
			fontSize: "14px",
			lineHeight: "21px",
			letterSpacing: LETTER_SPACING.none,
		},
		SM: {
			fontSize: "12px",
			lineHeight: "16px",
			letterSpacing: LETTER_SPACING.none,
		},
	},
};
